<template>
    <v-main>
      <v-container >
          <h2>Security</h2>
          <p class="">
            Manage your password, protect your account, and view additional
            security resources.
          </p>
          <v-row>
            <v-col cols="12" md="4">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card hover class="card" :elevation="hover ? 3 : 0" outlined>
                    <h4 class="px-3 pt-2 subtitle-1 text-dark">Update your Password</h4>
                    <div >
                      <v-img src="@/assets/forgot_password.svg" max-height="150"> </v-img>
                   </div>
   
                    <v-card-text>
                      <p class="h6 text-dark "> Password Security </p>
                      <p class="text-secondary">
                        Help keep your account safer by using a stronger
                        password.
                      </p>
                    </v-card-text>
                    <v-card-actions class="border-top">
                      <div class="w-100 text-right">
                        <v-btn no-action text color="#0A009B"  @click="dialog = true" :loading="loading">
                          Change password
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
      </v-container>
    <ChangePassword v-bind:user="info" @refresh="refreshMe" v-bind:dialog="dialog" v-model="dialog"></ChangePassword>
    </v-main>
</template>

<script>

import ChangePassword from '@/components/Profile/ChangePassword'
export default {
  components:{

    ChangePassword
  },
  props: ['myInfo'],
  data()
  {
    return {
      loading: true,
      info: null,
      dialog : false,
      status: false
    }
  },
  created()
  {
    if(this.myInfo)
    {
      this.populate()
      
    }
  },
  watch : {
    myInfo: function(val)
    {
      if(val)
      {
        this.populate()
      }
    }
  },
  methods: {
    populate()
    {
      if(!status)
      {
         this.info = this.myInfo
         this.loading = false
      }
    },
    refreshMe()
    {
      this.$emit('refresh', true)
    }
  }
};
</script>


<style scoped>
#btn {
  text-transform: none;
}

</style>